import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { Typography, message } from 'antd';
import { useEnableDefaultEmailRecipients } from '../../hooks/useEnableDefaultEmailRecipients';

type SetPrimaryRecordsDefaultEmailRecipientsModalProps = {
  open: boolean;
  count: number;
  filters?: ServerFilterInput;
  primaryObject: {
    name: string;
    pluralName: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const SetPrimaryRecordsDefaultEmailRecipientsModal = ({
  open,
  count = 1,
  primaryObject,
  filters,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: SetPrimaryRecordsDefaultEmailRecipientsModalProps) => {
  const { setDefaultEmailRecipients, loading } =
    useEnableDefaultEmailRecipients();

  const handleArchive = async () => {
    const res = await setDefaultEmailRecipients({ filters });

    const status =
      res.data?.setPrimaryRecordsDefaultEmailRecipients.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `Contacts have been set as default email recipients successfully.`,
      );
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `Contacts have been scheduled to be set as default email recipients.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The setting of default email recipients has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const name =
    count > 1 ? `${primaryObject.pluralName}` : `${primaryObject.name}`;

  return (
    <ModalConfirm
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        loading,
      }}
      okText="Enable"
      title={`Enable all contacts from selected ${count} ${name?.toLowerCase()} for emails`}
      onCancel={onCancel}
      onOk={handleArchive}
    >
      <Typography.Paragraph>
        {
          ' All associated contacts will be emailed when sending requests or reminders.'
        }
      </Typography.Paragraph>
    </ModalConfirm>
  );
};
