import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const SET_PRIMARY_RECORDS_DEFAULT_EMAIL_RECIPIENTS_MUTATION = graphql(`
  mutation SetPrimaryRecordsDefaultEmailRecipients($data: SetPrimaryRecordsDefaultEmailRecipientsInput!) {
    setPrimaryRecordsDefaultEmailRecipients(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useEnableDefaultEmailRecipients = () => {
  const [setDefaultEmailRecipientsMutation, { loading }] = useMutation(
    SET_PRIMARY_RECORDS_DEFAULT_EMAIL_RECIPIENTS_MUTATION,
  );

  const setDefaultEmailRecipients = ({
    filters,
  }: { filters?: ServerFilterInput }) => {
    return setDefaultEmailRecipientsMutation({
      variables: { data: { filter: filters || { and: [] } } },
    });
  };

  return { setDefaultEmailRecipients, loading };
};
